import React from "react";
import styled from "styled-components";

import StyledAvatar from "../../shared/components/Avatar";
import StyledSocialMediaIcons from "../../shared/components/SocialMediaIcons";

const MainUserProfile = ({ className }) => {
  const classPrefix = "main-user-profile";

  return (
    <div className={`${className} ${classPrefix}__wrapper`}>
      <div className={`${classPrefix}__avatar`}>
        <StyledAvatar></StyledAvatar>
      </div>
      <div className={`${classPrefix}__bio`}>
        <div className={`${classPrefix}__intro`}>Hi, I'm Elle!</div>
        <div className={`${classPrefix}__pronouns`}>(she / her)</div>
        <div>
          I'm a Software Crafter at{" "}
          <a
            href="https://8thlight.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            8th Light
          </a>
          , and Leadership Team Member and Mentor at{" "}
          <a
            href="https://codingblackfemales.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Coding Black Females
          </a>
          .
        </div>
        <br />
        <div>
          Here I'll share my journey and experiences as a career changer. I'm
          inspired by learning, sharing, community building, and interested in
          discussing bias and inclusion in tech.
        </div>
        <br />
        <StyledSocialMediaIcons />
      </div>
    </div>
  );
};

const StyledMainUserProfile = styled(MainUserProfile)`
  padding: 1em;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a {
    color: ${props => props.theme.color.orange};
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: ${props => props.theme.color.defaultText};
      background-color: ${props => props.theme.color.lighterGrey};
      border-radius: 4px;
    }
  }

  .main-user-profile__avatar {
    align-self: center;
  }

  .main-user-profile__bio {
    padding-bottom: 1.5em;
  }

  .main-user-profile__intro {
    font-size: 2em;
    font-weight: 700;
  }

  .main-user-profile__pronouns {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid ${props => props.theme.color.lightGrey};
  }

  .main-user-profile__follow-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .social-media-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    
    img {
      width: 30vw;
      max-width: 40vw;
      min-width: 20vw;
      height auto;
    }

    .user-profile__follow-buttons {
      justify-content: center;
    }

    .social-media-icons {
      justify-content: center;
    }
  }
`;

export default StyledMainUserProfile;
