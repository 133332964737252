import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import PostList from "../components/PostList";
import {
  generateSlicedBlogList,
  generateSlicedLogList,
} from "../shared/utils/postHelpers";
import PostListWithImage from "../components/PostListWithImage";
import StyledMainUserProfile from "../components/MainUserProfile";

export default ({ data }) => {
  const {
    description,
    title,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
    image,
  } = useSiteMetadata();
  const allPosts = data.allMdx.nodes;
  const blogPosts = generateSlicedBlogList(allPosts, 20);
  const logPosts = generateSlicedLogList(allPosts, 6);

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        authorName={authorName}
        author={authorName}
        defaultImage={image}
      />

      <MainUserProfile />

      <PostListWithImage
        posts={blogPosts}
        iconLabel={"view all"}
        link={"/blog"}
        searchLink={"/blog-search"}
        title={"Recent Blog Posts"}
        showDivider={true}
      />

      <PostList
        posts={logPosts}
        iconLabel={"view all"}
        link={"/logs"}
        searchLink={"/logs-search"}
        title={"Recent Logs"}
        description={
          "Shorter posts consisting of topics I search for regularly"
        }
        showDivider={true}
      />
    </Layout>
  );
};

const MainUserProfile = styled(StyledMainUserProfile)`
  width: 50%;
  margin: 1.5em auto 0 auto;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`;

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          date
          icon
          tags
          description
          cover {
            publicURL
          }
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
`;
