import React from "react";
import styled from "styled-components";

import avatar from "../../../assets/images/avatar/elle-hallal-avatar.png";

const Avatar = ({ className }) => {
  return (
    <div className={`${className} avatar`}>
      <img src={avatar}></img>
    </div>
  );
};

const StyledAvatar = styled(Avatar)`
  img {
    width: 15vw;
    max-width: 30vw;
    min-width: 15vw;
    height: auto;
  }


  @media (max-width: 700px) {
    img {
      width: 30vw;
      max-width: 40vw;
      min-width: 20vw;
      height: auto;
    }
  }
`;

export default StyledAvatar;
